//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-846:_784,_2888,_9840,_5482,_4938,_206,_5264,_4648;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-846')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-846', "_784,_2888,_9840,_5482,_4938,_206,_5264,_4648");
        }
      }catch (ex) {
        console.error(ex);
      }